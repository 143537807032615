<template>
  <div class="update-group-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Group' }">團隊管理</el-breadcrumb-item>
      <el-breadcrumb-item>編輯團隊</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        label-position="top"
        prop="photo_file"
        ref="groupForm"
        :model="group_form"
        :rules="group_rules"
      >
        <el-form-item label="團隊照片" prop="photo_file">
          <el-upload
            class="photo-uploader"
            action=""
            :show-file-list="false"
            :http-request="handleUpload"
            :before-upload="handleBeforeUpload"
            accept=".jpeg,.jpg,.png"
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="photo"
              alt="photo"
            />
            <i v-else class="el-icon-plus photo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="group_form.name_zh"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="group_form.name_en"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="中文介紹" prop="description_zh">
          <el-input
            type="textarea"
            v-model="group_form.description_zh"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文介紹" prop="description_en">
          <el-input
            type="textarea"
            v-model="group_form.description_en"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getGroup, updateGroup } from "@/api/group";

export default {
  name: "UpdateGroup",
  data() {
    return {
      group_form: {
        photo_file: "",
        name_zh: "",
        name_en: "",
        description_zh: "",
        description_en: "",
      },
      group_rules: {
        photo_file: [
          { required: true, message: "請上傳照片", trigger: "change" },
        ],
        name_zh: [
          { required: true, message: "請輸入姓名", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入姓名", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        description_zh: [
          { required: true, message: "請輸入敘述", trigger: "blur" },
        ],
        description_en: [
          { required: true, message: "請輸入敘述", trigger: "blur" },
        ],
      },
      preview_url: "",
    };
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetGroup();
  },
  methods: {
    async handleGetGroup() {
      const { photo, group_en, group_zh } = await getGroup(this.groupId);

      this.preview_url = `${process.env.VUE_APP_IMAGE_API}`.endsWith("/")
        ? `${process.env.VUE_APP_IMAGE_API}${photo}`
        : `${process.env.VUE_APP_IMAGE_API}/${photo}`;
      this.group_form.photo_file = photo;
      this.group_form.name_en = group_en.name;
      this.group_form.name_zh = group_zh.name;
      this.group_form.description_en = group_en.description;
      this.group_form.description_zh = group_zh.description;
    },
    handleBeforeUpload(file) {
      const imageType = ["image/jpeg", "image/jpg", "image/png"];

      if (imageType.indexOf(file.type) === -1) {
        this.$message.error("請上傳jpg, jpeg, png的格式");
        return false;
      }

      return true;
    },
    handleUpload(file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.preview_url = reader.result;
      });

      if (file) {
        reader.readAsDataURL(file.file);
        this.group_form.photo_file = file.file;
      }
    },
    handleSubmit() {
      this.$refs.groupForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();

          Object.keys(this.group_form).forEach((key) => {
            if (key === "photo_file") {
              if (this.group_form[key] instanceof File) {
                formData.append(key, this.group_form[key]);
                return;
              }
              return;
            }
            formData.append(key, this.group_form[key]);
          });

          await updateGroup(this.groupId, formData);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Group" });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-group-block {
  ::v-deep.photo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  ::v-deep.photo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1200px;
    height: 360px;
    line-height: 360px;
    text-align: center;
  }

  ::v-deep.photo {
    width: auto;
    height: 360px;
    display: block;
  }
}
</style>
